.App {
  text-align: center;
  max-width: 500px;
  background-color: #3c3c3c;
  margin: auto;
}

.App-logo {
  max-width: 90%;
  pointer-events: none;
  object-fit: contain;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.open-link {
  background: #fff;
  padding: 18px 2vw;
  border-radius: 500vw;
  overflow: hidden;
  width: 65%;
}

.open-link:hover {
  background: #efefef;
}

.open-link > div {
  font-weight: 600;
  color: #222;
  letter-spacing: 0.33ch;
}

.open-link > div:hover {
  font-weight: 700;
}
